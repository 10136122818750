import { ClientLayer, DivisionKey, GeojsonData, Levels, Style } from '../@types/Opportunity'

class Layer {
  level: Levels
  type: DivisionKey
  geojsonUrl: string
  breakpoint?: number
  style: Style
  data: GeojsonData[]

  constructor(level: Levels, type: DivisionKey, geojsonUrl: string, style: Style, data?: GeojsonData[], breakpoint?: number) {
    this.level = level
    this.type = type
    this.geojsonUrl = geojsonUrl
    this.breakpoint = breakpoint
    this.style = style
    this.data = data || []
  }

  setData(data: GeojsonData[]) {
    this.data = data
  }
}

export default Layer

function createLayer(level: Levels, type: DivisionKey, geojsonUrl: string, style: Style, data?: GeojsonData[], breakpoint?: number) {
  return new Layer(level, type, geojsonUrl, style, data, breakpoint)
}

export function createLayers(layers: ClientLayer[]) {
  const createdLayers = []
  for (const layer of layers) {
    createdLayers.push(createLayer(layer.level, layer.type, layer.geojsonUrl, layer.style, layer.data, layer.breakpoint))
  }

  return createdLayers
}
