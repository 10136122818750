import { NumberFormatOptions } from 'intl'
import { Currency } from '../@types/Opportunity'

type NumberFormatToCurrencyParams = {
  number: number,
  locale?: string,
  style?: NumberFormatOptions['style'],
  currency?: Currency,
  compactDisplay?: NumberFormatOptions['compactDisplay'],
  digit?: number,
  notation?: NumberFormatOptions['notation']
}

export function numberFormatToCurrency({
  number,
  locale = 'en-US',
  style = 'currency',
  currency = Currency.USD,
  compactDisplay = 'short',
  digit = 1,
  notation = 'compact',
}: NumberFormatToCurrencyParams) {

  return new Intl.NumberFormat(locale, {
    style,
    currency,
    notation,
    maximumFractionDigits: digit,
    compactDisplay: compactDisplay,
  }).format(number)
}