import { Levels } from '../@types/Opportunity'
import Layer from '../entities/layer.entities'

class LayerRepository {
  private layers: Map<string, Layer>

  constructor() {
    this.layers = new Map()
  }

  add(layer: Layer) {
    this.layers.set(layer.level, layer)
    return layer
  }

  get(level: Levels) {
    return this.layers.get(level)
  }

  getAll() {
    return this.layers
  }

  getLayerByZoom(zoom: number) {
    const lastLayer = Array.from(this.layers.values()).pop()
    for (const [key, layer] of this.layers) {
      if (layer.breakpoint && zoom < layer.breakpoint) return layer
    }
  
    return lastLayer
  }

  flushPersistentData() {
    this.layers = new Map()
  }
}

export default LayerRepository
