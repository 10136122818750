import { HttpRepository } from '@/features/opportunity/api/repositories/http.repository'
import { ClientConfig, Currency, DivisionKey, GeojsonData, Levels, Style } from '../../@types/Opportunity'
import MockAdapterClass from '@/features/mocks/axios.mock'
import { Feature, FeatureCollection, GeoJsonProperties, Geometry } from 'geojson'
import { COUNTRY_CODE } from '../../@types/LocaleCode'
import Layer from '../../entities/layer.entities'
import { downloadFile } from '@/utils/utilities.util'
import { FileExtension } from '../../@types/File'
import { AxiosRequestConfig } from 'axios'

type ClientConfigDto = {
  zoom: number
  center: {
    lat: number
    lng: number
  }
  country: COUNTRY_CODE
  layers: LayerDto[]
  mapId: string
}

type LayerDto = {
  level: Levels
  breakpoint?: number
  type: DivisionKey
  style: Style
  geojsonUrl?: string
  data?: GeojsonData[]
}

type CountryGeojsonsDto = {
  id: COUNTRY_CODE
  name: string
  geojsonUrl: string
  divisions: {
    [key in DivisionKey]: {
      geojsonUrl: string
    }
  }
  createdAt: string
}

type IntentionsDto = {
  paginated: boolean,
  total: number,
  rows: GeojsonData[]
}

class OpportunityService {
  private _repository: HttpRepository

  constructor(repository: HttpRepository) {
    this._repository = repository
  }

  async getClient(): Promise<ClientConfig> {
    const client = await MockAdapterClass.GET<ClientConfigDto>('')
    const countryGeojsons = await this._repository.GET<CountryGeojsonsDto>(`/countries/${client.country}`)

    for (const layer of client.layers) {
      layer.geojsonUrl = countryGeojsons.divisions[layer.type].geojsonUrl
    }

    return client as ClientConfig
  }

  async getGeojsonsFeatureCollection(uri: string): Promise<FeatureCollection> {
    const geojson = await this._repository.GET<Feature<Geometry, GeoJsonProperties>[]>(uri)
    const featureCollection: FeatureCollection = {
      type: "FeatureCollection",
      features: geojson
    }

    return featureCollection
  }

  async getIntentions(type: DivisionKey, country: COUNTRY_CODE) {
    const intentions = await this._repository.GET<IntentionsDto>(`/intentions?country=${country}&division=${type}`)
    return intentions.rows
  }

  async getGeojsonsAndIntentions(layer: Layer, countryCode: COUNTRY_CODE) {
    const featureCollection = await this.getGeojsonsFeatureCollection(layer.geojsonUrl)
    const intentions = await this.getIntentions(layer.type, countryCode)
    return { featureCollection, intentions }
  }

  async downloadExport() {
    const config: AxiosRequestConfig = {
      responseType: 'arraybuffer',
    }

    try {
      const file = await this._repository.POST<string>('/intentions/export', {}, config)
      downloadFile(file, `${Date.now()}.${FileExtension.XLSX}`)
    } catch (error) {
      console.error('Error during download:', error)
    }
  }
}

export default OpportunityService
