export type FeaturesResult = {
  features: Feature[]
  query: [string]
  type: string
}

export type Feature = {
  id: string
  type: string
  place_type: string[]
  relevance: number
  properties: {
    mapbox_id: string
    wikidata: string
  }
  text_en: string
  language_en: string
  place_name_en: string
  text: string
  language: string
  place_name: string
  bbox: [number, number, number, number]
  center: [number, number]
  geometry: Geometry
  context: Context[]
}

type Geometry = {
  type: string
  coordinates: [number, number]
}

type Context = {
  id: string
  mapbox_id: string
  wikidata: string
  short_code: string
  text_en: string
  language_en: string
  text: string
  language: string
}

export enum FEATURE_TYPES {
  REGION = 'region',
  DISTRICT = 'district'
}
