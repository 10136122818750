import { COUNTRY_CODE } from '../../@types/LocaleCode';
import { FeaturesResult, FEATURE_TYPES } from '../@types/MapBox';
import { HttpRepository } from '../repositories/http.repository';

class GeolocationService {
  private _repository: HttpRepository
  private URI = {
    mbGeocode: '/mbgeocode'
  }

  constructor(repository: HttpRepository) {
    this._repository = repository
  }

  async getSuggestions(input: string, language: any) {
    const params = {
      limit: 5,
      countries: [COUNTRY_CODE.US],
      types: [FEATURE_TYPES.REGION, FEATURE_TYPES.DISTRICT],
      language,
    }

    const { result } = await this._repository.POST<{ result: FeaturesResult }>(this.URI.mbGeocode, { input, params })
    return result.features
  }
}

export default GeolocationService
