import { GOOGLE_MAPS_API_KEY } from '@/features/opportunity/config/google-keys.config'
import { buildComparison } from '../../utils/operator.util'
import { GmapEvent } from '../../@types/Gmap'
import { ConditionalStyle, Properties, ConditionalStyleProperties, Style, GeojsonData } from '../../@types/Opportunity'
import { FieldConditionOperator } from '../../@types/Operator'

export const GEOJSON_ID = 'id'
const ZOOM_INIT = 0

class GmapImplementation {
  private map: google.maps.Map | null = null

  createScript(scriptId: string, elementId: string, options: google.maps.MapOptions): Promise<google.maps.Map> | undefined {
    return new Promise((resolve, reject) => {
      if (document.getElementById(scriptId)) {
        return resolve(this.initializeMap(elementId, options))
      }

      const googleMapsScript = document.createElement('script')
      googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}`
      googleMapsScript.id = scriptId
      googleMapsScript.async = true
      googleMapsScript.defer = true
  
      googleMapsScript.addEventListener('load', async () => {
        try {
          const map = await this.initializeMap(elementId, options)
          resolve(map)
        } catch (error) {
          reject(error)
        }
      })
  
      googleMapsScript.addEventListener('error', (error) => {
        reject(error)
      })
  
      document.body.appendChild(googleMapsScript)
    })
  }

  private async initializeMap(id: string, options: google.maps.MapOptions) {
    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary

    this.map = new Map(
      document.getElementById(id) as HTMLElement,
      options
    )

    return Promise.resolve(this.map)
  }

  getMap(): google.maps.Map | null {
    return this.map
  }

  getMapZoom() {
    return this.map?.getZoom()
  }

  zoomIn() {
    let currentZoom = this.getMapZoom() || ZOOM_INIT
    this.map?.setZoom(++currentZoom)
  }

  zoomOut() {
    let currentZoom = this.getMapZoom() || ZOOM_INIT
    this.map?.setZoom(--currentZoom)
  }

  // LAYERS
  createLayer() {
    return new google.maps.Data()
  }

  setLayerOnMap(layer: google.maps.Data) {
    layer.setMap(this.map)
  }

  hideLayerOnMap(layers: Map<string, google.maps.Data>) {
    for (const [keys, layer] of layers) {
      layer.setMap(null)
    }
  }

  setLayerStyle(layer: google.maps.Data, style: Style, geoJsonsData: GeojsonData[]) {
    layer.setStyle((geojson) => {
      let styleBase = { ...style.base }
      const geoJsonId = geojson.getProperty(GEOJSON_ID)
      const geoJsonData = geoJsonsData.find(geoJsonData => geoJsonData.divisionId === geoJsonId)
  
      if (!geoJsonData) return style.base
      
      styleBase = {
        ...styleBase,
        ...this.getGeojsonStylePerRules(style.rules, { totalCount: geoJsonData.totalCount })
      }
  
      return styleBase
    })
  }

  getGeojsonStylePerRules(rules: ConditionalStyle[], properties: ConditionalStyleProperties) {
    const operatorStopIteration = [FieldConditionOperator.INFERIOR, FieldConditionOperator.INFERIOR_EQUALS, FieldConditionOperator.EQUALS, FieldConditionOperator.STRICT_EQUALS]
    let style: Properties = {}
  
    for (const { property, operator, comparator, style: ruleStyle } of rules) {
      const propertyToCompare = properties[property]
  
      if (buildComparison(propertyToCompare, operator, comparator)) {
        style = {
          ...style,
          ...ruleStyle
        }

        if (operatorStopIteration.includes(operator)) break
      }
    }
  
    return style
  }

  // EVENT
  setZoomChangedListener(callback: Function) {
    this.map?.addListener(GmapEvent.ZOOM_CHANGED, callback)
  }

  setLayerMouseOverListener(layer: google.maps.Data,callback: Function) {
    layer.addListener(GmapEvent.MOUSE_OVER, callback)
  }

  setLayerMouseMoveListener(layer: google.maps.Data,callback: Function) {
    layer.addListener(GmapEvent.MOUSE_MOVE, callback)
  }

  setLayerMouseOutListener(layer: google.maps.Data,callback: Function) {
    layer.addListener(GmapEvent.MOUSE_OUT, callback)
  }

  setLayerMouseClickListener(layer: google.maps.Data,callback: Function) {
    layer.addListener(GmapEvent.CLICK, callback)
  }

  // RESET
  clearListener() {
    if (this.map) google.maps.event.clearInstanceListeners(this.map)
  }

  flushPersistentData() {
    this.clearListener()
    this.map = null
  }
}

export default GmapImplementation
