import { FieldConditionOperator } from './Operator'
import { COUNTRY_CODE } from './LocaleCode'

export type ClientConfig = {
  zoom: number
  center: LatLng,
  country: COUNTRY_CODE
  layers: ClientLayer[]
  mapId: string
}

export type Monetization = {
  currency: Currency,
  totalValue: number
}

export type LatLng = {
  lat: number
  lng: number
}

// LAYERS
export type ClientLayer = {
  level: Levels
  breakpoint?: number
  type: DivisionKey
  geojsonUrl: string
  style: Style
  data?: GeojsonData[]
}

export type Style = {
  base: Properties
  rules: ConditionalStyle[]
}

export type Properties = {
  fillColor?: string
  fillOpacity?: number
  strokeOpacity?: number
  strokeWeight?: number
  strokeColor?: string
}

export type ConditionalStyle = {
  label: {
    en: string
  }
  property: keyof ConditionalStyleProperties
  operator: FieldConditionOperator
  comparator: string
  style: Properties
}

export type ConditionalStyleProperties = {
  totalCount: number
}

// GEOJSON
export type GeojsonData = {
  divisionName: string
  divisionId: string
  totalCount: number
  totalValue: number
}

// ENUM
export enum Levels {
  LEVEL_1 = '1',
  LEVEL_2 = '2',
  LEVEL_3 = '3'
}

export enum Currency {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  CAD = 'CAD'
}

export enum DivisionKey {
  LEVEL_1 = 'level1',
  LEVEL_2 = 'level2',
  LEVEL_3 = 'level3'
}
