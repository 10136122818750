import { COUNTRY_CODE } from '../@types/LocaleCode'
import { ClientLayer, LatLng } from '../@types/Opportunity'

class Client {
  zoom: number
  center: LatLng
  country: COUNTRY_CODE
  mapId: string
  layers: ClientLayer[]

  constructor(zoom: number, center: LatLng, country: COUNTRY_CODE, mapId: string, layers: ClientLayer[]) {
    this.zoom = zoom
    this.center = center
    this.country = country
    this.mapId = mapId
    this.layers = layers
  }
}

export default Client

export function createClient(zoom: number, center: LatLng, country: COUNTRY_CODE, mapId: string, layers: ClientLayer[]) {
  return new Client(zoom, center, country, mapId, layers)
}

