import { ref } from 'vue'
import GmapImplementation from './gmap.implementation'
import { GeojsonData, ConditionalStyle, Levels, ConditionalStyleProperties, Style } from '../../@types/Opportunity'

export const LEVEL_KEY = 'level'

class MapService {
  private SCRIPT_ID = 'SCRIPT-MAP'
  ELEMENT_ID = 'ELEMENT-MAP'
  private _provider: GmapImplementation
  isLoading = ref(false)
  private layers: Map<string, google.maps.Data> = new Map()

  constructor(provider: GmapImplementation) {
    this._provider = provider
  }

  setLoading(isLoading: boolean) {
    this.isLoading.value = isLoading
  }

  async createScript(options: google.maps.MapOptions) {
    return this._provider.createScript(this.SCRIPT_ID, this.ELEMENT_ID, options)
  }

  // MAP
  getMapZoom() {
    return this._provider.getMapZoom()
  }

  zoomIn() {
    this._provider.zoomIn()
  }

  zoomOut() {
    this._provider.zoomOut()
  }

  // LAYERS
  createLayer(level: Levels) {
    const mapLayer = this._provider.createLayer()
    mapLayer.set(LEVEL_KEY, level)
    return mapLayer
  }

  saveLayer(level: Levels, layer: google.maps.Data) {
    this.layers.set(level, layer)
    return layer
  }

  getLayer(level: Levels) {
    return this.layers.get(level)
  }

  getLayers() {
    return this.layers
  }

  setLayerOnMap(layer: google.maps.Data) {
    this._provider.setLayerOnMap(layer)
  }

  hideLayerOnMap() {
    this._provider.hideLayerOnMap(this.layers)
  }

  setLayerStyle(layer: google.maps.Data, style: Style, geoJsonsData: GeojsonData[]) {
    this._provider.setLayerStyle(layer, style, geoJsonsData)
  }

  getLayerStyleBasedOnRules(rules: ConditionalStyle[], properties: ConditionalStyleProperties) {
    return this._provider.getGeojsonStylePerRules(rules, properties)
  }

  // EVENTS
  handleZoomChange(callback: Function) {
    this._provider.setZoomChangedListener(callback)
  }

  handleLayerMouseOver(layer: google.maps.Data, callback: Function) {
    this._provider.setLayerMouseOverListener(layer, callback)
  }

  handleLayerMouseMove(layer: google.maps.Data, callback: Function) {
    this._provider.setLayerMouseMoveListener(layer, callback)
  }

  handleLayerMouseOut(layer: google.maps.Data, callback: Function) {
    this._provider.setLayerMouseOutListener(layer, callback)
  }

  handleLayerMouseClick(layer: google.maps.Data, callback: Function) {
    this._provider.setLayerMouseClickListener(layer, callback)
  }

  // RESET
  clearListener() {
    for (const [_, layer] of this.layers) {
      google.maps.event.clearInstanceListeners(layer)
    }
  }

  flushPersistentData() {
    this.clearListener()
    this.layers = new Map()
    this._provider.flushPersistentData()
  }
}

export default MapService
