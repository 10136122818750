import { Levels } from '../@types/Opportunity'
import Layer from '../entities/layer.entities'
import LayerRepository from '../repositories/layer.repository'

export class LayerService {
  static _instance = new LayerService()
  private static _repository: LayerRepository

  constructor() {}

  public static setRepository(repository: LayerRepository) {
    this._repository = repository
  }
 
  saveLayer(layer: Layer) {
    return LayerService._repository.add(layer)
  }

  saveLayers(layers: Layer[]) {
    const savedLayers = []
    for (const layer of layers) {
      savedLayers.push(LayerService._repository.add(layer))
    }

    return savedLayers
  }

  getLayer(level: Levels) {
    return LayerService._repository.get(level)
  }

  getLayers() {
    return LayerService._repository.getAll()
  }

  getLayerByZoom(zoom: number) {
    return LayerService._repository.getLayerByZoom(zoom)
  }

  flushPersistentData() {
    LayerService._repository.flushPersistentData()
  }
}

export default LayerService._instance
